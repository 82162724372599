import Feed from '@/components/Feed'
import Layout from '@/components/Layout/Layout'
import { NextSeo } from 'next-seo'
import Preloader from '@/components/Layout/Preloader'
import { parseCookies } from 'nookies'
import { useUser } from '@/hooks/useUser'

const Index = ({ pageProps }) => {
  const { loading, user } = useUser()
  const { cookies, flush, token } = pageProps

  if (loading) {
    return (
      <Layout allowWelcomeMat>
        <Preloader isFullScreen message='Loading' />
      </Layout>
    )
  } else if (!loading && !user) {
    // console.log('no user')
  }

  return (
    <>
      <NextSeo
        title='Scramblers – by Bicester Heritage'
        description='An automotive movement that celebrates Bicester Heritage and the people and cars it supports.'
      />

      <Layout allowWelcomeMat cookies={cookies} baseUrl='/' token={token}>
        <Feed filter={{ product: false }} flush={flush === '1'} />
      </Layout>
    </>
  )
}

export async function getServerSideProps (context) {
  const urlParse = require('url-parse')
  const cookies = parseCookies(context)
  const query = urlParse(context.req.url, true).query
  const returnProps = {
    cookies
  }

  if (query.flush && query.flush === '1') {
    returnProps.flush = '1'
  }

  return {
    props: {
      ...returnProps,
      token: process.env.VERIFICATION_TOKEN_SECRET
    }
  }
}

export default Index
